import { DataAvailabilityInfo } from './../types/data-availability-info.type';
import { DataAvailabilityService } from './../services/data-availability.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModuleType, PageType } from '../enums/page-type.enum';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { MenuService } from '../services/menu.service';
import { NavigationService } from '../services/navigation.service';
import { SelectionService } from '../services/selection.service';

@Injectable({ providedIn: 'root' })
export class MenuAuthGuard {
  constructor(
    private menuService: MenuService,
    private navigationService: NavigationService,
    private breadcrumbService: BreadcrumbService,
    private selectionService: SelectionService,
    private dataAvailabilityService: DataAvailabilityService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.navigationService.setCurrentRoute(route);

    const module = this.navigationService.currentModule;
    const page = this.navigationService.currentPage;
    const subPage = this.navigationService.currentSubPage;

    this.breadcrumbService.clear();
    if (module === ModuleType.Admin) {
      this.menuService.initializeAdminMenuItems();
    } else if (page === PageType.RuntimeAnalysis && subPage != null) {
      this.selectionService.clear();
    } else {
      const dataAvailability = await this.dataAvailabilityService.getDataAvailability();
      this.menuService.initializeMainMenuItems(dataAvailability);
    }
    return true;
  }
}
